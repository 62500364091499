import 'primeflex/primeflex.css';
import 'vue-advanced-cropper/dist/style.css';
import '@cyhnkckali/vue3-color-picker/dist/style.css';
import './assets/styles/variables.css';
import '@/sass/main.scss';

import { createApp } from 'vue';

import { createPinia } from 'pinia';
import { Userpilot } from 'userpilot';
import PrimeVue from 'primevue/config';
import Vue3Lottie from 'vue3-lottie';
import { plugin, defaultConfig } from '@formkit/vue';

import Tooltip from 'primevue/tooltip';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';

import Sentry from '@tagboard/lib-sentry-vue';

// Import core library and component
// not really extraneous but the linter thinks so - TODO: look into it more later
// eslint-disable-next-line import/no-extraneous-dependencies
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faFacebook,
  faGoogle,
  faInstagram,
  faXTwitter,
} from '@awesome.me/kit-a54fb3a664/icons/classic/brands';

// eslint-disable-next-line import/no-extraneous-dependencies
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Import icons from '@awesome.me/kit-a54fb3a664' - both solid and regular
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  faBlockQuestion as fasBlockQuestion,
  faBold as fasBold,
  faBoltLightning as fasBoltLightning,
  faBookmark as fasBookmark,
  faCheck as fasCheck,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCirclePlus as fasCirclePlus,
  faCopy as fasCopy,
  faDesktop as fasDesktop,
  faDownload as fasDownload,
  faEllipsisVertical as fasEllipsisVertical,
  faFileCsv as fasFileCsv,
  faFolder as fasFolder,
  faFolderPlus as fasFolderPlus,
  faFontCase as fasFontCase,
  faGrid2 as fasGrid2,
  faGripVertical as fasGripVertical,
  faHashtag as fasHashtag,
  faImage as fasImage,
  faItalic as fasItalic,
  faList as fasList,
  faListOl as fasListOl,
  faMapLocationDot as fasMapLocationDot,
  faMoon as fasMoon,
  faPersonWalkingArrowRight as fasPersonWalkingArrowRight,
  faShoppingBasket as fasShoppingBasket,
  faStar as fasStar,
  faStrikethrough as fasStrikethrough,
  faTable as fasTable,
  faText as fasText,
  faUpload as fasUpload,
  faVideo as fasVideo,
  faX as fasX,
} from '@awesome.me/kit-a54fb3a664/icons/classic/solid';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  faArrowLeft as farArrowLeft,
  faArrowUp as farArrowUp,
  faArrowDown as farArrowDown,
  faArrowRightFromBracket as farArrowRightFromBracket,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faBarsFilter as farBarsFilter,
  faBookmark as farBookmark,
  faChartMixed as farChartMixed,
  faCheck as farCheck,
  faCircleExclamation as farCircleExclamation,
  faCircleHalfStroke as farCircleHalfStroke,
  faCircleInfo as farCircleInfo,
  faCirclePlus as farCirclePlus,
  faCircleQuestion as farCircleQuestion,
  faClipboard as farClipboard,
  faClone as farClone,
  faCode as farCode,
  faCog as farCog,
  faDownLeftAndUpRightToCenter as farDownLeftAndUpRightToCenter,
  faFileExport as farFileExport,
  faFileImport as farFileImport,
  faGear as farGear,
  faLink as farLink,
  faMagnifyingGlass as farMagnifyingGlass,
  faMessageQuote as farMessageQuote,
  faPalette as farPalette,
  faPen as farPen,
  faPencil as farPencil,
  faPhotoFilm as farPhotoFilm,
  faPlus as farPlus,
  faPollPeople as farPollPeople,
  faRocket as farRocket,
  faShareNodes as farShareNodes,
  faSpinnerThird as farSpinnerThird,
  faSquarePollHorizontal as farSquarePollHorizontal,
  faStar as farStar,
  faScrewdriverWrench as farScrewdriverWrench,
  faSun as farSun,
  faSunBright as farSunBright,
  faTableLayout as farTableLayout,
  faTrashCan as farTrashCan,
  faTriangleExclamation as farTriangleExclamation,
  faTvRetro as farTvRetro,
  faUpRightAndDownLeftFromCenter as farUpRightAndDownLeftFromCenter,
  faUser as farUser,
  faUserPlus as farUserPlus,
  faExchangeAlt as farExchangeAlt,
  faArrowLeftToLine as farArrowLeftToLine,
  faCopy as farCopy,
  faUsersMedical as farUsersMedical,
  faFolder as farFolder,
  faFolderOpen as farFolderOpen,
  faSortNumericDown as farSortNumericDown,
  faSortNumericUp as farSortNumericUp,
  faArrowUpAZ as farArrowUpAZ,
  faArrowDownZA as farArrowDownZA,
  faFolderPlus as farFolderPlus,
  faXmark as farXmark,
  faFilePen as farFilePen,
  faEye as farEye,
  faPaperPlane as farPaperPlane,
  faLaptop as farLaptop,
  faMobile as farMobile,
  faInputText as farInputText,
  faAlignLeft as farAlignLeft,
  faAlignCenter as farAlignCenter,
  faAlignRight as farAlignRight,
  faLandMineOn as farLandMineOn,
  faLaptopCode as farLaptopCode,
  faMinus as farMinus,
  faGripDotsVertical as farGripDotsVertical,
  faChartColumn as farChartColumn,
  faLock as farLock,
  faLockOpen as farLockOpen,
  faRectangleHistoryCirclePlus as farRectangleHistoryCirclePlus,
  faCartPlus as farCartPlus,
} from '@awesome.me/kit-a54fb3a664/icons/classic/regular';

import {
  faSparkles as fadSparkles,
} from '@awesome.me/kit-a54fb3a664/icons/duotone/solid';

import {
  faMessageQuote as falMessageQuote,
  faCopy as falCopy,
  faPlus as falPlus,
} from '@awesome.me/kit-a54fb3a664/icons/classic/light';

import {
  faTagHome as fakTagHome,
  faTagInteractive as fakTagInteractive,
  faTagGraphics as fakTagGraphics,
  faSocialIsolated as fakSocialIsolated,
  faSocialLowerthird as fakSocialLowerthird,
  faSocialLowerthirdscroll as fakSocialLowerthirdscroll,
  faSocialCinematic as fakSocialCinematic,
  faSocialStories as fakSocialStories,
  faSocialTicker as fakSocialTicker,
  faSocialGrid as fakSocialGrid,
  faSocialWaterfall as fakSocialWaterfall,
  faSocialMural as fakSocialMural,
  faSocialCarousel as fakSocialCarousel,
  faInteractivePoll as fakInteractivePoll,
  faInteractivePollResults as fakInteractivePollResults,
  faInteractiveHeatmap as fakInteractiveHeatmap,
  faInteractiveHashtagMeter as fakInteractiveHashtagMeter,
  faInteractiveHashtagPoll as fakInteractiveHashtagPoll,
  faInteractiveLeaderboard as fakInteractiveLeaderboard,
  faInteractiveCta as fakInteractiveCta,
  faInteractiveCountdown as fakInteractiveCountdown,
  faInteractiveGraphic as fakInteractiveGraphic,
  faInteractiveRedditPoll as fakInteractiveRedditPoll,
  faInteractiveTwitterPoll as fakInteractiveTwitterPoll,
  faInteractiveVimeo as fakInteractiveVimeo,
  faInteractiveYoutube as fakInteractiveYoutube,
  faRegularUsersRotateRight as fakRegularUsersRotateRight,
  faRegularScrewdriverWrenchUser as fakRegularScrewdriverWrenchUser,
  faRegularCodeUser as fakRegularCodeUser,
  faRegularLockKeyholeRotateRight as fakRegularLockKeyholeRotateRight,
  faSportradar as fakSportradar,
  faPhotoshop as fakPhotoshop,
  faTagAnalytics2 as fakTagAnalytics2,
  faTagSocial as fakTagSocial,
  faTagProducer as fakTagProducer,
  faRegularFolderCirclePlus as fakRegularFolderCirclePlus,
  faLightFolderCirclePlus as fakLightFolderCirclePlus,
  faRegularArrowUpShortWideClock as fakRegularArrowUpShortWideClock,
  faRegularArrowDownShortWideClock as fakRegularArrowDownShortWideClock,
  faTagShareit as fakTagShareit,
  faTgbPages as fakTgbPages,
} from '@awesome.me/kit-a54fb3a664/icons/kit/custom';

import useThemeSwitcher from '@/composables/useThemeSwitcher';
import App from './App.vue';
import router from './router';

import {
  USERPILOT_TOKEN,
  SENTRY_DSN,
  NODE_ENV,
  COMMIT_HASH,
  SENTRY_TRACES_SAMPLE_RATE,
} from './config/appConfig';

const { themePreference, applyTheme } = useThemeSwitcher();
applyTheme(themePreference.value);

window.matchMedia('(prefers-color-scheme: dark)')
  .addEventListener('change', ({ matches }) => {
    if (matches) {
      themePreference.value = 'dark';
    } else {
      themePreference.value = 'light';
    }
  });

library.add(
  // Solid icons
  fasBlockQuestion,
  fasBold,
  fasBoltLightning,
  fasBookmark,
  fasCheck,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasCirclePlus,
  fasCopy,
  fasDesktop,
  fasDownload,
  fasEllipsisVertical,
  fasFileCsv,
  fasFolder,
  fasFolderPlus,
  fasFontCase,
  fasGrid2,
  fasGripVertical,
  fasHashtag,
  fasImage,
  fasItalic,
  fasList,
  fasListOl,
  fasMapLocationDot,
  fasMoon,
  fasPersonWalkingArrowRight,
  fasShoppingBasket,
  fasStar,
  fasStrikethrough,
  fasTable,
  fasText,
  fasUpload,
  fasVideo,
  fasX,

  // Regular icons
  farArrowLeft,
  farArrowUp,
  farArrowDown,
  farArrowRightFromBracket,
  farArrowUpRightFromSquare,
  farBarsFilter,
  farBookmark,
  farChartMixed,
  farCheck,
  farCircleExclamation,
  farCircleHalfStroke,
  farCircleInfo,
  farCirclePlus,
  farCircleQuestion,
  farClipboard,
  farClone,
  farCog,
  farCode,
  farDownLeftAndUpRightToCenter,
  farFileExport,
  farFileImport,
  farGear,
  farLink,
  farMagnifyingGlass,
  farMessageQuote,
  farPalette,
  farPhotoFilm,
  farPen,
  farPencil,
  farPlus,
  farPollPeople,
  farRocket,
  farShareNodes,
  farSpinnerThird,
  farScrewdriverWrench,
  farSquarePollHorizontal,
  farStar,
  farSun,
  farSunBright,
  farTableLayout,
  farTrashCan,
  farTriangleExclamation,
  farTvRetro,
  farUpRightAndDownLeftFromCenter,
  farUser,
  farUserPlus,
  farExchangeAlt,
  farCopy,
  farUsersMedical,
  farFolder,
  farFolderPlus,
  farFolderOpen,
  farSortNumericDown,
  farSortNumericUp,
  farArrowUpAZ,
  farArrowDownZA,
  farXmark,
  farFilePen,
  farEye,
  farPaperPlane,
  farLaptop,
  farMobile,
  farInputText,
  farAlignLeft,
  farAlignCenter,
  farAlignRight,
  farLandMineOn,
  farLaptopCode,
  farMinus,
  farGripDotsVertical,
  farChartColumn,
  farLock,
  farLockOpen,
  farRectangleHistoryCirclePlus,
  farCartPlus,
  // Light icons
  falCopy,
  falPlus,
  farArrowLeftToLine,

  // DuoTone icons
  fadSparkles,

  // Custom icons
  fakTagHome,
  fakTagInteractive,
  fakTagGraphics,
  fakRegularUsersRotateRight,
  fakSocialIsolated,
  fakSocialLowerthird,
  fakSocialLowerthirdscroll,
  fakSocialCinematic,
  fakSocialStories,
  fakSocialTicker,
  fakSocialGrid,
  fakSocialWaterfall,
  fakSocialMural,
  fakSocialCarousel,
  fakInteractivePoll,
  fakInteractivePollResults,
  fakInteractiveHeatmap,
  fakInteractiveHashtagMeter,
  fakInteractiveHashtagPoll,
  fakInteractiveLeaderboard,
  fakInteractiveCta,
  fakInteractiveCountdown,
  fakInteractiveGraphic,
  fakInteractiveRedditPoll,
  fakInteractiveTwitterPoll,
  fakInteractiveVimeo,
  fakInteractiveYoutube,
  fakRegularCodeUser,
  fakRegularScrewdriverWrenchUser,
  fakRegularLockKeyholeRotateRight,
  fakSportradar,
  fakPhotoshop,
  fakTagAnalytics2,
  fakTagSocial,
  fakTagProducer,
  fakRegularFolderCirclePlus,
  fakLightFolderCirclePlus,
  fakRegularArrowUpShortWideClock,
  fakRegularArrowDownShortWideClock,
  fakTagShareit,
  fakTgbPages,

  // Light icons
  falMessageQuote,

  // Social Brands
  faFacebook,
  faGoogle,
  faInstagram,
  faXTwitter,
);

// Initialize Userpilot
Userpilot.initialize(USERPILOT_TOKEN);

const app = createApp(App);

app.use(Vue3Lottie);
app.use(createPinia());
app.use(router);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(DialogService);
app.use(ToastService);
app.use(plugin, defaultConfig);

try {
  Sentry.init({
    dsn: SENTRY_DSN,
    env: NODE_ENV,
    release: COMMIT_HASH,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    app,
    router,
  });

  window.Sentry = Sentry.client;
} catch (err) {
  console.error('Failed to initialize Sentry:', err);
}

app.directive('tooltip', Tooltip);

app.component('FontAwesomeIcon', FontAwesomeIcon);

app.mount('#app');
